import { useGetAuth } from "contexts/AuthContext";
import { useRouter } from "next/router";
import { useState } from "react";
import { useMutation } from "../../tools/api";
import { encryptToken } from "tools/api/functions/hash";

export const useForgotPin = (isPush) => {
  const { push } = useRouter();
  const { user } = useGetAuth();
  const [errorOTP, setIsErrorOTP] = useState(false);
  const email = user?.email;

  const { mutation } = useMutation({
    method: "post",
    url: "/pin/otp_forgot",
    afterSuccess: (_, payload) => {
      const { otp_code } = payload;
      const encryptedOTP = encryptToken(otp_code);
      isPush && push({ pathname: "reset-pin", query: { otp: encryptedOTP } });
    },
    handleError: (error) => {
      const msg = error?.response?.data?.message;
      // if (msg == "Invalid OTP Code") return setIsErrorOTP(true);
      // if (msg == "Invalid OTP Code") return setRemainingOTPAttempts(error);
    },
  });

  const {
    mutation: preOTP,
    loading: loadingPreRequest,
    result: { mobile },
    setResult,
  } = useMutation({
    url: "/otp/pre_request_otp",
    method: "post",
    resultFormatter: (res, _, payload) => {
      const mobile = res?.data?.number;
      return { ...payload, mobile };
    },
  });

  const { mutation: reqOTP, loading: loadingRequest } = useMutation({
    url: "/otp/request_user_otp",
    method: "post",
  });

  const resetOTP = () => {
    setIsErrorOTP(false);
    setResult((p) => {
      const { mobile, ...rest } = p;
      return rest;
    });
  };

  const loading = loadingPreRequest || loadingRequest;

  const preRequestOTP = async () => {
    preOTP({ email });
  };

  const requestOTP = (otp_with) => {
    setIsErrorOTP(false);
    reqOTP({ otp_with, email });
  };

  const reset = async (payload) => {
    setIsErrorOTP(false);
    mutation({ ...payload });
  };

  return {
    mobile,
    reset,
    preRequestOTP,
    requestOTP,
    resetOTP,
    loading,
    errorOTP,
  };
};
